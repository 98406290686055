// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { Labeled, TextField, useLocale } from 'react-admin';

import { Parameters, ParameterRule } from '@/types';

type ModelParametersInfoProps = {
    source?: string;
    parameterValues?: Parameters;
    parameterRules: ParameterRule[];
};

const ModelParametersInfo: FC<ModelParametersInfoProps> = ({
    source,
    parameterValues,
    parameterRules,
}) => {
    const locale = useLocale();
    const rules = parameterRules
        .filter((rule) => !rule.advanced);
    const advancedRules = parameterRules
        .filter((rule) => rule.advanced);

    const renderParameter = (rule: ParameterRule): JSX.Element => {
        const sourceRule = source ? `${source}.${rule.name}` : ''

        return (
            <Labeled
                source={sourceRule}
                label={rule.label[locale]}
            >
                <TextField
                    source={sourceRule}
                    defaultValue={
                        parameterValues ? parameterValues[rule.name] as string : rule.default as string
                    }
                />
            </Labeled>
        )
    };

    return (
        <Stack spacing={1}>
            <Grid container spacing={1}>
                {
                    rules.map((rule: ParameterRule, index: React.Key | null | undefined) => (
                        <Grid key={index} size={{ xs: 12, sm: rule.type === 'text' ? 12 : 6 }}>
                            {renderParameter(rule)}
                        </Grid>
                    ))
                }
                {
                    advancedRules.map((rule: ParameterRule, index: React.Key | null | undefined) => (
                        <Grid key={index} size={{ xs: 12, sm: rule.type === 'text' ? 12 : 6 }}>
                            {renderParameter(rule)}
                        </Grid>
                    ))
                }
            </Grid>
        </Stack>
    );
}

export default ModelParametersInfo;
