// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps } from 'react-admin';

const VectorStoreResourceProps: ResourceProps = {
    name: 'vector-stores',
};

export {
    VectorStoreResourceProps,
};
