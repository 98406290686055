// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { Dispatch, SetStateAction, createContext } from 'react';

import {
    ProviderModelConfigWithEntity, Assistant, Prompt, ChatSettings,
    SessionMessage, SessionFloatActionKey,
} from '@/types';

export interface SessionContextInterface {
    model: ProviderModelConfigWithEntity | undefined,
    assistant: Assistant | undefined,
    prompt: Prompt | undefined,

    webSocketConnection: WebSocket | undefined,

    userInput: string,
    setUserInput: Dispatch<SetStateAction<string>>,
    chatSettings: ChatSettings,
    setChatSettings: Dispatch<SetStateAction<ChatSettings>>,
    sessionMessages: SessionMessage[] | [],
    setSessionMessages: Dispatch<SetStateAction<SessionMessage[] | []>>

    isLoadingInitialData: boolean,
    isGenerating: boolean,
    setIsGenerating: Dispatch<SetStateAction<boolean>>,

    isResponsiveLayout: boolean,
    floatActions: SessionFloatActionKey[],
};

export const SessionContext = createContext<SessionContextInterface>({
    model: undefined,
    assistant: undefined,
    prompt: undefined,

    webSocketConnection: undefined,

    userInput: '',
    setUserInput: () => {},
    chatSettings: {},
    setChatSettings: () => {},
    sessionMessages: [],
    setSessionMessages: () => {},

    isLoadingInitialData: true,
    isGenerating: false,
    setIsGenerating: () => {},

    isResponsiveLayout: true,
    floatActions: [],
});
