// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, {
    useState, FC, ReactNode, useEffect
} from 'react';
import { useRecordContext, useDataProvider } from 'react-admin';
import {
    ProviderModelConfigWithEntity, Assistant, Prompt, ParameterRule,
    SessionMessage, ChatSettings, Parameters, SessionFloatActionKey, Session,
} from '@/types';
import { SessionContext } from '@/context';
import { createOptions } from '@/authProvider';
import { createGenerateURL } from '@/utils';

interface SessionStateProps {
    children: ReactNode,
    useResponsiveLayout?: boolean,
    actions?: SessionFloatActionKey[],
};

export const SessionState: FC<SessionStateProps> = ({
    children,
    useResponsiveLayout,
    actions,
}) => {
    const session = useRecordContext<Session>();
    if (!session) return null;

    const dataProvider = useDataProvider();

    const [model, setModel] = useState<ProviderModelConfigWithEntity | undefined>(undefined);
    const [assistant, setAssistant] = useState<Assistant | undefined>(undefined);
    const [prompt, setPrompt] = useState<Prompt | undefined>(undefined);

    const [webSocketConnection, setWebSocketConnection] = useState<WebSocket | undefined>(undefined);

    const [userInput, setUserInput] = useState<string>('');
    const [chatSettings, setChatSettings] = useState<ChatSettings>({});
    const [sessionMessages, setSessionMessages] = useState<SessionMessage[] | []>([]);

    const [isLoadingInitialData, setIsLoadingInitialData] = useState<boolean>(true);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);

    const [isResponsiveLayout, setIsResponsiveLayout] = useState<boolean>(true);
    const [floatActions, setFloatActions] = useState<SessionFloatActionKey[]>(actions || []);

    const fetchSessionMessages = async (): Promise<void> => {
        // get stored messages
        dataProvider.getList('messages', {
            filter: {session: session.id},
            pagination: { page: 1, perPage: -1 }
        }).then((messageData: any) => {
            const { data: messages } = messageData;
            setSessionMessages(messages);
            if (useResponsiveLayout !== undefined) setIsResponsiveLayout(useResponsiveLayout);
        });
    }

    const initializeSettings = async (): Promise<void> => {
        if (!model || !model.entity.parameter_rules) return;
        // initialize chat settings
        setChatSettings({
            ...chatSettings,
            parameters: model.entity.parameter_rules.reduce(
                (parameters: Parameters, rule: ParameterRule) => {
                    if (!Object.keys(parameters).includes(rule.name)) {
                        parameters[rule.name] = rule.default;
                    }
                    return parameters;
                }, {})
        });
    };

    const fetchInitialData = async (): Promise<void> => {
        switch(session.type) {
            case 'llm':
                setModel(session.related_model);
                setAssistant(undefined);
                setPrompt(undefined);
                setFloatActions([
                    SessionFloatActionKey.SETTINGS,
                    SessionFloatActionKey.INFO,
                    SessionFloatActionKey.USAGE,
                    SessionFloatActionKey.NEW,
                ]);
                break;
            case 'assistant':
                break;
            case 'prompt':
                setModel(session.related_model);
                setAssistant(undefined);
                setPrompt(session.related_prompt);
                setFloatActions([
                    SessionFloatActionKey.INFO,
                    SessionFloatActionKey.USAGE,
                    SessionFloatActionKey.NEW,
                ]);
                break;
            default:
                break;
        }
        // initialize chat settings
        await initializeSettings();
        // get stored messages
        await fetchSessionMessages();
    };

    const createWebSocketConnection = async (): Promise<void> => new Promise((resolve, reject) => {
        const url = createGenerateURL(session);
        const options = createOptions(url);
        if (!options.user || !options.headers) {
            reject(new Error('Not authenticated'));
            return;
        }
        const teamHeader = options.headers.get('X-Team');
        if (!teamHeader) {
            reject(new Error('X-Team header is missing'));
            return;
        }
        const webSocket = new WebSocket(url, ['json', options.user.token.replace('Token ', ''), teamHeader]);
        webSocket.onopen = () => {
            setWebSocketConnection(webSocket);
            resolve();
        };

        webSocket.onerror = (error) => {
            reject(error);
            webSocket.close();
        };
    });

    useEffect(() => {
        (async () => {
            await fetchInitialData().then(() => setIsLoadingInitialData(false));
            await createWebSocketConnection();
        })();
    }, []);

    const contextValue = React.useMemo(() => ({
        model,
        assistant,
        prompt,

        webSocketConnection,

        userInput,
        setUserInput,
        chatSettings,
        setChatSettings,
        sessionMessages,
        setSessionMessages,

        isLoadingInitialData,
        isGenerating,
        setIsGenerating,

        isResponsiveLayout,
        floatActions,
    }), [isLoadingInitialData, userInput, sessionMessages, isGenerating, chatSettings]);

    return (
        <SessionContext.Provider value={contextValue}>
            {children}
        </SessionContext.Provider>
    );
};
