// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTranslate, useLocale } from 'react-admin';

import { GlobalContext } from '@/context';
import { MenuItemWithDialog } from '@/common';

const AboutMenuItem: FC = () => {
    const translate = useTranslate();
    const locale = useLocale();
    const { aboutSystem } = useContext(GlobalContext);

    if (!aboutSystem) return null;

    return (
        <MenuItemWithDialog
            LeftIcon={<InfoIcon />}
            label='label.user_menu.about.title'
            MenuItemProps={{
                id: 'about-menu-item',
                divider: true,
            }}
            dialog={{
                id: 'about-dialog',
                maxWidth: 'sm',
                fullWidth: true,
                title: translate('label.user_menu.about.title'),
                dialogTitle: (
                    <>
                        {aboutSystem.name[locale]}
                        <Typography variant='caption' display='block'>
                            Version:
                            {' '}
                            {
                                aboutSystem.version
                            }
                        </Typography>
                    </>
                ),
                disableBackdropClick: true,
                ContentProps: {
                    id: 'about-dialog-content',
                    dividers: true,
                },
                dialogContent: (
                    <>
                        <Typography variant='subtitle2'>
                            {aboutSystem.description[locale]}
                        </Typography>
                        <br />
                        <Divider />
                        <Typography variant='overline' display='block' sx={{ textAlign: 'right' }}>
                            {aboutSystem.license[locale]}
                        </Typography>
                    </>
                ),
            }}
        />
    );
};

export default AboutMenuItem;
