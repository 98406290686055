// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import {
    SaveButton, useTranslate, Button, useNotify, useRefresh,
    Form, EditBase, useRecordContext, BooleanInput, SelectInput, required
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { TeamRole } from '@/types';
import { ButtonWithDialog } from '@/common';
import { getChoicesFromEnum } from '@/utils';

const MembershipEditButtonView: FC = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const { reset } = useFormContext();
    const refresh = useRefresh();
    const record = useRecordContext();

    return (

        <ButtonWithDialog
            label=''
            startIcon={<EditIcon />}
            size='small'
            sx={{ minWidth: 'auto' }}
            dialog={{
                title: translate('resources.memberships.title.edit_dialog', { username: record?.user?.username }),
                maxWidth: 'xs',
                fullWidth: true,
                disableBackdropClick: true,
                ContentProps: {
                    dividers: true,
                },
                dialogContent: (
                    <>
                        <BooleanInput
                            source='is_active'
                            variant='outlined'
                            fullWidth
                        />
                        <SelectInput
                            source='role'
                            choices={getChoicesFromEnum(TeamRole)}
                            validate={required()}
                            variant='outlined'
                            fullWidth
                        />
                    </>
                ),
                dialogAction: (onClose) => (
                    <>
                        <Button
                            onClick={() => onClose && onClose({}, 'escapeKeyDown')}
                            label='ra.action.cancel'
                            startIcon={<ErrorOutlineIcon />}
                        />
                        <SaveButton
                            label='ra.action.save'
                            type='button'
                            mutationOptions={{
                                onSuccess: () => {
                                    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
                                    reset();
                                    refresh();
                                    if (onClose) {
                                        onClose({}, 'escapeKeyDown');
                                    }
                                }
                            }}
                            icon={<SaveIcon />}
                        />
                    </>
                ),
            }}
        />
    );
};

type MembershipEditButtonProps = object;

const MembershipEditButton: FC<MembershipEditButtonProps> = () => {
    const record = useRecordContext();
    if (!record) {
        return null;
    }

    return (
        <EditBase
            resource='memberships'
            id={record.id}
            mutationMode='pessimistic'
        >
            <Form>
                <MembershipEditButtonView />
            </Form>
        </EditBase>
    )
};

export default MembershipEditButton;
