// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import { Form, useTranslate } from 'react-admin';

import { SessionContext } from '@/context';
import { ChatModelSettings, ParameterRule } from '@/types';
import { ConfigurationInputProps } from '@/common';
import { ModelParametersInput } from '@/provider/model';

type ChatModelSettingFormProps = object

const ChatModelSettingForm: FC<ChatModelSettingFormProps> = () => {
    const { model, chatSettings, setChatSettings } = useContext(SessionContext);

    const translate = useTranslate();
    if (!model || !model.entity.parameter_rules) {
        return null;
    }

    const chatModelSettings = chatSettings as ChatModelSettings;

    const onChange: ConfigurationInputProps['onChange'] = (value, rule) => {
        const settings = {...chatModelSettings};
        settings.parameters[rule.name] = value;
        setChatSettings(settings)
    }

    return (
        <Form
            defaultValues={chatModelSettings.parameters}
        >
            <ModelParametersInput
                rules={model.entity.parameter_rules.map(
                    (rule: ParameterRule) => ({...rule, name: `related_model.config.parameters.${rule.name}`})
                )}
                onChange={onChange}
                advancedSectionTitle={translate('label.chat.settings.advanced')}
            />
        </Form>
    )
};

export default ChatModelSettingForm;
