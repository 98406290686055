// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps } from 'react-admin';

import PromptGroupCreateInSelect from './PromptGroupCreateInSelect';
import PromptGroupField from './PromptGroupField';
import PromptGroupInput from './PromptGroupInput';

const PromptGroupResourceProps: ResourceProps = {
    name: 'prompt-groups',
};

export {
    PromptGroupResourceProps,
    PromptGroupCreateInSelect,
    PromptGroupField,
    PromptGroupInput,
};
