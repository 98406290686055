// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { Identifier } from 'react-admin';

import { ProviderModelConfigWithEntity } from './model';

export interface PromptGroup {
    id: number;
    name: string;
    description: string;
    color: string;
}

export enum PromptType {
    SIMPLE = 'simple',
    ADVANCED = 'advanced',
}

export enum PromptStatus {
    DRAFTED = 'drafted',
    PUBLISHED = 'published',
}

export interface Prompt {
    id: number;
    name: string;
    description: string;
    pre_prompt: string;
    type: PromptType;
    status: PromptStatus;
    avatar: string;
    related_model: ProviderModelConfigWithEntity;
    group_id: Identifier;
    related_test_session: Identifier;
}
