// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import { useRecordContext } from 'react-admin';

import { Session } from '@/types';

import ChatModelSettingForm from './ChatModelSettingsForm';

type ChatSettingFormProps = object

const ChatSettingForm: FC<ChatSettingFormProps> = () => {
    const session = useRecordContext<Session>();

    if (
        !session ||
        (session.type === 'llm' && !session.related_model) ||
        (session.type === 'assistant' && !session.related_assistant) ||
        (session.type === 'prompt' && !session.related_prompt)
    ) {
        return null;
    }

    if (session.type === 'llm' || session.type === 'prompt') {
        return (<ChatModelSettingForm />)
    }

    return null;
};

export default ChatSettingForm;
