// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useState } from 'react';

import { SessionContext } from '@/context';
import { Message } from '@/message';
import { ChatBotMessage } from '@/types';

type SessionMessagesProps = object;

const SessionMessages: FC<SessionMessagesProps> = () => {
    const { sessionMessages } = React.useContext(SessionContext);

    const [editingMessage, setEditingMessage] = useState<ChatBotMessage>();

    return sessionMessages
        .sort((a, b) => a.sequence - b.sequence)
        .map((sessionMessage, index, array) => (
            <>
                <Message
                    key={`${index}-user`}
                    onStartEdit={setEditingMessage}
                    isEditing={editingMessage?.id === sessionMessage.id}
                    isLast={false}
                    // onCancelEdit={() => setEditingMessage(undefined)}
                    message={{
                        id: sessionMessage.id,
                        content: sessionMessage.query,
                        role: 'user',
                        owner: sessionMessage.owner,
                    }}
                />
                <Message
                    key={`${index}-bot`}
                    onStartEdit={setEditingMessage}
                    isEditing={editingMessage?.id === sessionMessage.id}
                    isLast={index === array.length - 1}
                    // onCancelEdit={() => setEditingMessage(undefined)}
                    message={{
                        id: sessionMessage.id,
                        content: sessionMessage.answer,
                        role: 'assistant',
                    }}
                />
            </>
        ));
};

export default SessionMessages;
