// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps } from 'react-admin';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

import SessionCreate from './SessionCreate';
import SessionList from './SessionList';
import SessionShow from './SessionShow';

const SessionResourceProps: ResourceProps = {
    name: 'sessions',
    list: SessionList,
    show: SessionShow,
    icon: WorkspacesIcon,
};

export {
    SessionResourceProps,
    SessionCreate,
};
