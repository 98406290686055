// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog as MuiDialog, DialogProps as MuiDialogProps } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent, { DialogContentProps as MuiDialogContentProps } from '@mui/material/DialogContent';
import DialogActions, { DialogActionsProps as MuiDialogActionsProps } from '@mui/material/DialogActions';

export const StyledDialog = styled(MuiDialog, {
    name: 'GFDialog',
    slot: 'root',
})(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogProps extends MuiDialogProps {
    title: string;
    dialogTitle?: ReactNode;
    dialogContent: ReactNode;
    dialogAction?: (onClose: MuiDialogProps['onClose']) => ReactNode;
    ContentProps?: MuiDialogContentProps;
    ActionsProps?: MuiDialogActionsProps;
}

const Dialog: FC<DialogProps> = ({
    onClose,
    open,
    title,
    dialogTitle,
    dialogContent,
    dialogAction,
    ContentProps,
    ActionsProps,
    ...rest
}) => (
    <MuiDialog
        onClose={onClose}
        aria-labelledby={title}
        open={open}
        {...rest}
    >
        <DialogTitle
            sx={{
                m: 0, pr: 2, pl: 2, pt: 1, pb: 1
            }}
            id='dialog-title'
        >
            {
                dialogTitle || (
                    title
                )
            }
        </DialogTitle>
        <IconButton
            aria-label='close'
            onClick={() => onClose && onClose({}, 'escapeKeyDown')}
            sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
            })}
        >
            <CloseIcon />
        </IconButton>
        <DialogContent {...ContentProps}>
            {dialogContent}
        </DialogContent>
        {
            dialogAction && (
                <DialogActions {...ActionsProps}>
                    {dialogAction(onClose)}
                </DialogActions>
            )
        }
    </MuiDialog>
);

export default Dialog;
