// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useRecordContext, useTranslate } from 'react-admin';

import { ProviderConfiguration } from '@/types';
import ProviderCreateButton from './form/ProviderCreateButton';
import ProviderEditButton from './form/ProviderEditButton';

type ProviderHelpProps = object;

const ProviderSetup: FC<ProviderHelpProps> = () => {
    const providerConfiguration = useRecordContext<ProviderConfiguration>();
    const translate = useTranslate();

    if (!providerConfiguration || !providerConfiguration.help) return null;
    return (
        <Box display='flex' flexDirection='column' p={1} alignItems='center'>
            <Stack direction='row' spacing={1}>
                <Typography variant='caption' component='span'>
                    {translate('resources.providers.fields.credentials.label')}
                </Typography>
                {
                    providerConfiguration.user_configuration.active ? (
                        <Tooltip title={translate('resources.providers.fields.credentials.enabled')}>
                            <RadioButtonCheckedIcon color='success' fontSize='small' />
                        </Tooltip>
                    ) : (
                        <Tooltip title={translate('resources.providers.fields.credentials.disabled')}>
                            <RadioButtonUncheckedIcon color='error' fontSize='small' />
                        </Tooltip>
                    )
                }
            </Stack>
            {
                providerConfiguration.user_configuration.active ? (
                    <ProviderEditButton />
                ) : (
                    <ProviderCreateButton />
                )
            }
        </Box>
    );
};

export default ProviderSetup;
