// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useState, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslate } from 'react-admin';

export interface TabItems {
    [key: string]: ReactNode;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel: FC<TabPanelProps> = (props) => {
    const {
        children, value, index, ...other
    } = props;

    return (
        <Box
            role='tabpanel'
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            width='100%'
            height='700px'
            overflow='scroll'
            {...other}
        >
            {value === index && children}
        </Box>
    );
};

function a11yProps(index: number): object {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

interface TabsControllerProps extends TabsProps {
    tabLabels: string[];
    children: ReactNode | ReactNode[];
}

const TabsController: FC<TabsControllerProps> = ({
    tabLabels, children, orientation, ...rest
}) => {
    const translate = useTranslate();
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
        setValue(newValue);
    };

    return (
        <Box
            sx={
                orientation === 'vertical' ? (
                    { display: 'flex', flexGrow: 1 }
                ) : ({ width: '100%' })
            }
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='controlled tabs'
                    orientation={orientation}
                    {...rest}
                >

                    {
                        tabLabels.map((label, index) => (
                            <Tab key={index} label={translate(label)} {...a11yProps(index)} />
                        ))
                    }
                </Tabs>
            </Box>
            {
                Array.isArray(children) ? (
                    children.map((child, index) => (
                        <TabPanel key={index} value={value} index={index}>
                            {child}
                        </TabPanel>
                    ))
                ) : (
                    <TabPanel value={value} index={0}>
                        {children}
                    </TabPanel>
                )
            }
        </Box>
    );
};

export default TabsController;
