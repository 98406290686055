// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {
    SaveButton, useTranslate, Button, useNotify, useRefresh,
    CreateBase, Form, EditBase, useRecordContext,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { ButtonWithDialog } from '@/common';
import TeamForm from './TeamForm';

type TeamCreateEditButtonProps = {
    edit?: boolean;
};

const TeamCreateEditButtonView: FC<TeamCreateEditButtonProps> = ({
    edit = false,
}) => {
    const translate = useTranslate();
    const notify = useNotify();
    const { reset } = useFormContext();
    const refresh = useRefresh();

    let title = translate('resources.teams.title.create_dialog');
    let label = 'resources.teams.label.create_btn';
    if (edit) {
        const record = useRecordContext();
        title = translate('resources.teams.title.edit_dialog', { name: record?.name });
        label = 'ra.action.edit';
    }

    return (

        <ButtonWithDialog
            label={label}
            startIcon={edit ? <EditIcon />:<GroupAddIcon />}
            dialog={{
                title,
                maxWidth: 'sm',
                fullWidth: true,
                disableBackdropClick: true,
                ContentProps: {
                    dividers: true,
                },
                dialogContent: (
                    <TeamForm />
                ),
                dialogAction: (onClose) => (
                    <>
                        <Button
                            onClick={() => onClose && onClose({}, 'escapeKeyDown')}
                            label='ra.action.cancel'
                            startIcon={<ErrorOutlineIcon />}
                        />
                        <SaveButton
                            label={edit ? 'ra.action.save' : 'resources.teams.label.save_btn'}
                            type='button'
                            mutationOptions={{
                                onSuccess: () => {
                                    if (edit) {
                                        notify('resources.teams.message.updated');
                                    } else {
                                        notify('resources.teams.message.created');
                                    }
                                    reset();
                                    refresh();
                                    if (onClose) {
                                        onClose({}, 'escapeKeyDown');
                                    }
                                }
                            }}
                            icon={edit ? <SaveIcon />:<AddIcon />}
                        />
                    </>
                ),
            }}
        />
    );
};

const TeamCreateEditButton: FC<TeamCreateEditButtonProps> = ({
    edit = false,
}) => {
    if (edit) {
        const record = useRecordContext();
        if (!record) {
            return null;
        }

        return (
            <EditBase
                resource='teams'
                id={record.id}
                mutationMode='pessimistic'
            >
                <Form>
                    <TeamCreateEditButtonView edit />
                </Form>
            </EditBase>
        )
    }

    return (
        <CreateBase>
            <Form>
                <TeamCreateEditButtonView />
            </Form>
        </CreateBase>
    )
};

export default TeamCreateEditButton;
