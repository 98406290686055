// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { forwardRef, RefObject } from 'react';
import MenuItem, { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';
import { SxProps } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';

export type IconMenuItemProps = {
    MenuItemProps?: MuiMenuItemProps;
    className?: string;
    disabled?: boolean;
    label?: string;
    leftIcon?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    ref?: RefObject<HTMLLIElement>;
    rightIcon?: React.ReactNode;
    sx?: SxProps;
};

const IconMenuItem = forwardRef<HTMLLIElement, IconMenuItemProps>((
    {
        MenuItemProps, className, label, leftIcon, rightIcon, ...props
    },
    ref
) => (
    <MenuItem {...MenuItemProps} ref={ref} className={className} {...props}>
        <ListItemIcon sx={{ minWidth: 5 }}>
            {leftIcon}
        </ListItemIcon>
        {label}
        <ListItemIcon sx={{ minWidth: 5, flexDirection: 'row-reverse' }}>
            {rightIcon}
        </ListItemIcon>
    </MenuItem>
));

export default IconMenuItem;
