// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, {
    FC, useContext, useState, useEffect
} from 'react';
import Box from '@mui/material/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import RepeatIcon from '@mui/icons-material/Repeat';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styled from '@mui/material/styles/styled';

import { SessionContext } from '@/context';
import{ WithTooltip, IconButton } from '@/common';

interface OwnerStateProps {
    isAssistant: boolean;
    isLast: boolean;
};

const Root = styled(Box, {
    name: 'GFMessageActions',
    slot: 'Root',
})<{ ownerState: OwnerStateProps }>(({ theme, ownerState }) => ({
    display: 'flex',
    marginTop: theme.spacing(1),
    marginLeft: ownerState.isAssistant ? theme.spacing(2):0,
    marginBottom: ownerState.isLast ? theme.spacing(1):0,
    flexDirection: ownerState.isAssistant ? 'row':'row-reverse',
    '& > *:not(:first-child)': {
        marginLeft: ownerState.isAssistant ? theme.spacing(1):0,
        marginRight: ownerState.isAssistant ? 0:theme.spacing(1),
    },
    minHeight: 30,
}));

interface MessageActionsProps {
    isLast: boolean;
    isEditing: boolean;
    isHovering: boolean;
    isAssistant: boolean;
    onCopy: () => void;
    onEdit: () => void;
    onRegenerate: () => void;
};

const MessageActions: FC<MessageActionsProps> = ({
    isLast,
    isEditing,
    isHovering,
    isAssistant,
    onCopy,
    onEdit,
    onRegenerate,
}) => {
    const { isGenerating } = useContext(SessionContext);

    const [showCheckmark, setShowCheckmark] = useState(false);

    const handleCopy = (): void => {
        onCopy();
        setShowCheckmark(true);
    }

    useEffect(() => {
        if (showCheckmark) {
            const timer = setTimeout(() => {
                setShowCheckmark(false)
            }, 2000)

            return () => clearTimeout(timer)
        }
        return undefined;
    }, [showCheckmark]);

    return (isLast && isGenerating) || isEditing ? null : (
        <Root ownerState={{ isAssistant, isLast }}>
            {
                (isHovering || isLast) && (
                    <WithTooltip
                        title='label.message.action.copy'
                        trigger={(
                            <span>
                                <IconButton
                                    ariaLabel='label.message.action.copy'
                                    onClick={showCheckmark ? undefined:handleCopy}
                                    color='primary'
                                    size='small'
                                >
                                    {
                                        showCheckmark ? (
                                            <CheckCircleOutlineIcon fontSize='small' />
                                        ) : (
                                            <ContentCopyIcon fontSize='small' />
                                        )
                                    }
                                </IconButton>
                            </span>
                        )}
                        arrow
                    />
                )
            }
            {
                !isAssistant && isHovering && (
                    <WithTooltip
                        title='label.message.action.edit'
                        trigger={(
                            <span>
                                <IconButton
                                    ariaLabel='label.message.action.edit'
                                    onClick={onEdit}
                                    color='primary'
                                    size='small'
                                >
                                    <EditIcon fontSize='small' />
                                </IconButton>
                            </span>
                        )}
                        arrow
                    />
                )
            }
            {
                isLast && (
                    <WithTooltip
                        title='label.message.action.regenerate'
                        trigger={(
                            <span>
                                <IconButton
                                    ariaLabel='label.message.action.regenerate'
                                    onClick={onRegenerate}
                                    color='primary'
                                    size='small'
                                >
                                    <RepeatIcon fontSize='small' />
                                </IconButton>
                            </span>
                        )}
                        arrow
                    />
                )
            }
        </Root>
    )
};

export default MessageActions;
