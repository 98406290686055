// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { DataProvider } from 'react-admin';

import { ResourceURL } from '@/utils';
import { fetchJsonWithAuthToken } from '../authProvider';

export default <DataProvider> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getList: async (resource: string, params: any) => {
        const url = ResourceURL(`/${resource}/configuration`);
        const { json } = await fetchJsonWithAuthToken(url);

        return {
            data: json.results,
            total: json.count,
        };
    }
};
