// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps } from 'react-admin';
import AssistantIcon from '@mui/icons-material/Assistant';

import assistantDataProvider from './assistantDataProvider';
import AssistantList from './AssistantList';

const AssistantResourceProps: ResourceProps = {
    name: 'assistants',
    list: AssistantList,
    icon: AssistantIcon,
};

export {
    AssistantResourceProps,
    assistantDataProvider,
};
