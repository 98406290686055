// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    CreateBase, Form, useTranslate, useLocale,
    useRecordContext,
} from 'react-admin';

import { ProviderConfiguration, Provider } from '@/types';
import { ButtonWithDialog } from '@/common';

import ProviderSetupForm from './ProviderSetupForm';
import ProviderFormActions from './ProviderFormActions';
import ProviderHelp from './ProviderHelp';

type ProviderCreateButtonProps = object;

const ProviderCreateButton: FC<ProviderCreateButtonProps> = () => {
    const translate = useTranslate();
    const locale = useLocale();
    const providerConfiguration = useRecordContext<ProviderConfiguration>();

    if (!providerConfiguration) {
        return null;
    }

    const transform = (data: Provider): Provider => ({
        ...data,
        provider_name: providerConfiguration.provider,
    });

    return (
        <CreateBase<Provider>
            resource='providers'
            record={providerConfiguration}
            transform={transform}
            redirect={false}
        >
            <Form>
                <ButtonWithDialog
                    label='action.setup'
                    sx={{
                        width: '100%',
                    }}
                    dialog={{
                        title: translate('label.setup', { name: providerConfiguration.label[locale] }),
                        maxWidth: 'sm',
                        fullWidth: true,
                        disableBackdropClick: true,
                        ContentProps: {
                            dividers: true,
                        },
                        dialogContent: (
                            <ProviderSetupForm
                                credentialFormSchemas={
                                    providerConfiguration.provider_credential_schema.credential_form_schemas
                                }
                            />
                        ),
                        dialogAction: (onClose) => (
                            <>
                                <ProviderHelp />
                                <ProviderFormActions
                                    onClose={() => onClose && onClose({}, 'escapeKeyDown')}
                                />
                            </>
                        )
                    }}
                />
            </Form>
        </CreateBase>
    );
};

export default ProviderCreateButton;
