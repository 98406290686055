// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useRecordContext } from 'react-admin';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import { PromptGroup } from '@/types';

interface StyledBoxProps {
    color: string;
};

const StyledBox = styled(Box, {
    name: 'PromptGroupField',
    slot: 'box',
})<{ ownerState: StyledBoxProps }>(({ ownerState }) => ({
    width: 24,
    height: 24,
    border: '1px solid #000',
    backgroundColor: ownerState.color,
}));

interface PromptGroupFieldProps {
    showLabel?: boolean;
};

const PromptGroupField: FC<PromptGroupFieldProps> = ({
    showLabel = true,
}) => {
    const record = useRecordContext<PromptGroup>();

    if (!record) {
        return null;
    }

    return (
        <Stack
            direction='row'
            spacing={1}
        >
            <Tooltip title={record.name}>
                <StyledBox
                    ownerState={{ color: record.color }}
                />
            </Tooltip>
            {showLabel && (
                <Typography>
                    {record.name}
                </Typography>
            )}
        </Stack>
    );
};

export default PromptGroupField;
