// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const enUS: TranslationMessages = {
    ...englishMessages,
    resources: {
        sessions: {
            name: 'Session |||| Sessions',
            fields: {
                name: 'Name',
                type: 'Type',
                mode: 'Mode',
                related_model: {
                    label: 'Related Model',
                    provider_name: 'Provider',
                    model_name: 'Model',
                    config: {
                        parameters: 'Model Parameters',
                    }
                },
                related_prompt: 'Prompt',
                related_assistant: 'Assistant',
                owner: {
                    username: 'Created By',
                },
                created_date: 'Created At',
                updated_date: 'Updated At',
                usage: {
                    title: 'Usage',
                    total_messages: 'Total Messages',
                    total_tokens: 'Total Tokens',
                    total_price: 'Total Cost',
                    total_input_tokens: 'Input',
                    total_output_tokens: 'Output',
                    total_input_price: 'Input',
                    total_output_price: 'Output',
                    per_day: 'Daily Cost',
                }
            },
        },
        memberships: {
            name: 'Membership |||| Memberships',
            fields: {
                is_active: 'Active',
                joined_date: 'Joined Date',
                role: 'Role',
                status: {
                    label: 'Status',
                    invited: 'Invited on %{when} by %{by}',
                    joined: 'Joined on %{when}',
                    not_active: 'Not Active',
                }
            },
            label: {
                resend: 'Resend Invitation',
                invite: 'Invite',
            },
            title: {
                invite: 'Invite Member',
                resend: 'Resend Invitation',
                edit_dialog: 'Edit \'%{username}\' membership',
            },
            message: {
                invited: 'Member invited',
            }
        },
        providers: {
            name: 'Provider |||| Providers',
            fields: {
                credentials: {
                    label: 'Credentials',
                    enabled: 'Enabled',
                    disabled: 'Disabled',
                }
            },
        },
        models: {
            name: 'Model |||| Models',
            fields: {
                model_type: 'Type',
            }
        },
        teams: {
            name: 'Team |||| Teams',
            fields: {
                name: 'Name',
                description: 'Description',
                user_role: 'Role',
                created_by: 'Created By',
                created_date: 'Created Date',
                updated_date: 'Updated Date',
            },
            label: {
                create_btn: 'New Team',
                save_btn: 'Create',
                edit_btn: 'Edit Team',
            },
            title: {
                create_dialog: 'New Team',
                edit_dialog: 'Edit \'%{name}\' Team',
            },
            message: {
                created: 'Team created',
                updated: 'Team updated',
            }
        },
        prompts: {
            name: 'Prompt |||| Prompts',
            fields: {
                name: 'Name',
                description: 'Description',
                type: 'Type',
                status: 'Status',
                pre_prompt: 'Prompt',
                q: 'Search name and description',
                group: {
                    id: 'Group',
                },
                related_model: {
                    label: 'Related Model',
                    provider_name: 'Provider',
                    model_name: 'Model',
                    config: {
                        parameters: 'Model Parameters',
                    }
                },
            },
            message: {
                start_test_title: 'Start Testing session',
                start_test_content: 'to verify the prompt ...',
            }
        },
        'prompt-groups': {
            name: 'Prompt Group |||| Prompt Groups',
            fields: {}
        }
    },
    label: {
        test: 'Test',
        draft: 'Draft',
        publish: 'Publish',
        add_new: 'Add New %{name}',
        setup: 'Setup %{name}',
        providers: 'Providers',
        advanced: 'Advanced',
        members: 'Members',
        view: 'View',
        use: 'Use',
        session: {
            new: 'New Chat',
        },
        chat: {
            title: 'Chat',
            actions: 'Chat Actions',
            info: 'Session Info',
            usage: 'Session Usage',
            input: {
                send: 'Send message',
                stop: 'Stop',
                attach: 'Attach file',
                placeholder: 'Ask anything!'
            },
            settings: {
                btn_label: 'Chat Settings',
                advanced: 'Advanced',
            },
            scroll: {
                top: 'Scroll to top',
                bottom: 'Scroll to bottom',
            }
        },
        message: {
            action: {
                copy: 'Copy',
                edit: 'Edit',
                regenerate: 'Regenerate',
            }
        },
        user_menu: {
            settings: {
                title: 'Settings',
            },
            about: {
                title: 'About',
            }
        }
    },
    message: {
        item_updated: '%{name} updated',
        delete_dialog: {
            disable_title: 'Disable %{resource}',
            disable_content: 'Are you sure you want to disable this %{resource}?',
            title: 'Delete \'%{name}\' %{resource}',
            content: 'Are you sure you want to delete this %{resource}?',
        },
        chat: {
            new: {
                title: 'Start new chat',
                description: 'by selecting LLM model, prompt or assistant ...',
            },
            bot: {
                title: 'Setup and send',
                description: 'a message to start chat ...',
            },
        }
    },
    action: {
        setup: 'Setup',
    }
};

export default enUS;
