// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { Card as MuiCard } from '@mui/material';
import { styled } from '@mui/material/styles';

const Card = styled(MuiCard, {
    name: 'GFCard',
    slot: 'root',
})(() => ({
    margin: '20px 0 10px 0',
}));

export default Card;
