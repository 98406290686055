// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { DataProvider } from 'react-admin';

import defaultDataProvider from './defaultDataProvider';

import { assistantDataProvider } from '@/assistant';
import { collectionDataProvider } from '@/collection';
import { modelDataProvider } from '@/provider/model';
import { promptDataProvider } from '@/prompt';
import { providerDataProvider } from '@/provider';
import { systemDataProvider } from '@/system';
import { userDataProvider } from '@/user';

type Providers = {
    [key: string]: DataProvider
};

const providers: Providers = {
    system: systemDataProvider,
    teams: defaultDataProvider,
    assistants: assistantDataProvider,
    messages: defaultDataProvider,
    memberships: defaultDataProvider,
    invitations: defaultDataProvider,
    prompts: {
        ...defaultDataProvider,
        ...promptDataProvider,
    },
    'prompt-groups': defaultDataProvider,
    models: {
        ...defaultDataProvider,
        ...modelDataProvider
    },
    providers: {
        ...defaultDataProvider,
        ...providerDataProvider,
    },
    users: userDataProvider,
    sessions: defaultDataProvider,
    collections: {
        ...collectionDataProvider,
        ...defaultDataProvider,
    },
    'vector-stores': defaultDataProvider,
};

type DataProviderMethod = (resource: string, params: any) => any;

const createMethod = (method: string): DataProviderMethod => (resource: string, params: any) => {
    if (providers[resource] && typeof providers[resource][method] === 'function') {
        return providers[resource][method](resource, params);
    }
    throw new Error(`Method ${method} not found on provider for resource ${resource}`);
};

const dataProviderWrapper: DataProvider = {
    getList: createMethod('getList'),
    getOne: createMethod('getOne'),
    getMany: createMethod('getMany'),
    getManyReference: createMethod('getManyReference'),
    create: createMethod('create'),
    update: createMethod('update'),
    updateMany: createMethod('updateMany'),
    delete: createMethod('delete'),
    deleteMany: createMethod('deleteMany'),
    // Add any additional methods here
    getSessionTypes: createMethod('getSessionTypes'),
    getAbout: createMethod('getAbout'),
    self: createMethod('self'),
    getParameterRules: createMethod('getParameterRules'),
    getConfiguration: createMethod('getConfiguration'),
};

export {
    dataProviderWrapper as dataProvider,
};
