// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BiotechIcon from '@mui/icons-material/Biotech';
import { useTranslate } from 'react-admin';

import { PagePlaceholderWithIcon } from '@/common';

type TestSessionPlaceholderProps = object;

const TestSessionPlaceholder: FC<TestSessionPlaceholderProps> = () => {
    const translate = useTranslate();

    return (
        <PagePlaceholderWithIcon
            icon={<BiotechIcon />}
            sx={{
                height: '80%',
            }}
        >
            <Box>
                <Typography variant='h4' gutterBottom>
                    {translate('resources.prompts.message.start_test_title')}
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                    {translate('resources.prompts.message.start_test_content')}
                </Typography>
            </Box>
        </PagePlaceholderWithIcon>
    );
}

export default TestSessionPlaceholder;
