// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

import { GlobalContext } from '@/context';

interface UserFieldProps {
    user: any
}

const UserField: FC<UserFieldProps> = ({ user }) => {
    const { currentUser } = useContext(GlobalContext);

    let avatar = user.avatar ? user.avatar : undefined;
    if (!avatar) {
        if (user.first_name && user.first_name !== '') {
            avatar = user.first_name[0].toUpperCase();
        } else {
            avatar = user.email[0].toUpperCase();
        }
    }

    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-start'
            flexGrow={1}
        >
            <Avatar
                src={user.avatar ? user.avatar : undefined}
                sx={{ width: 24, height: 24, marginRight: '8px' }}
            >
                {avatar}
            </Avatar>
            <Box
                display='flex'
                flexDirection='column'
            >
                <Typography variant='subtitle2'>{`${user.first_name} ${user.last_name} ${currentUser?.id === user.id ? '(You)':''}`}</Typography>
                <Typography variant='body2'>{user.email}</Typography>
            </Box>
        </Box>
    );
};

export default UserField;
