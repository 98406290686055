// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { DatasetType, DatasetElementType } from '@mui/x-charts/internals';
import { format } from 'date-fns';

import { SessionDailyUsage } from '@/types';
import { lastMonthDays } from '@/utils/date';

export const formatBytes = (bytes: number): string => {
    if (bytes < 1024) {
        return `${bytes} B`;
    } if (bytes < 1024 * 1024) {
        return `${(bytes / 1024).toFixed(0)}K`;
    } if (bytes < 1024 * 1024 * 1024) {
        return `${(bytes / (1024 * 1024)).toFixed(0)}M`;
    }
    return `${(bytes / (1024 * 1024 * 1024)).toFixed(0)}G`;
};

interface Option {
    id: string;
    name: string;
};

export const getChoicesFromEnum = (EnumType: any): Option[] => Object.keys(EnumType).map((key) => (
    { id: EnumType[key as keyof typeof EnumType], name: EnumType[key as keyof typeof EnumType] }
));

export const processDailyUsage = (usagePerDay: SessionDailyUsage[]): DatasetType => lastMonthDays.map((day) => {
    const key = format(new Date(day), 'yyyy-MM-dd');
    const usage: DatasetElementType<string | number | Date | null | undefined> = {
        day: key,
        total_messages: 0,
        total_price: 0,
    }
    const dayUsage = usagePerDay.find((u) => format(new Date(u.day), 'yyyy-MM-dd') === key);
    if (dayUsage) {
        usage.total_messages = dayUsage.total_messages;
        usage.total_price = dayUsage.total_price;
    }
    return usage;
});

export const truncateText = (description: string, wordLimit: number): string => {
    const words = description.split(' ');
    if (words.length <= wordLimit) {
        return description;
    }
    return `${words.slice(0, wordLimit).join(' ')}...`;
};
