// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    useTranslate, useGetResourceLabel, RecordContextProvider,
    ReferenceInput, required, SelectInput, SelectInputProps,
} from 'react-admin';

import PromptGroupField from './PromptGroupField';
import PromptGroupCreateInSelect from './PromptGroupCreateInSelect';

interface PromptGroupInputProps extends Omit<SelectInputProps, 'create'> {
    source?: string;
    showCreateOption?: boolean;
}

const PromptGroupInput: FC<PromptGroupInputProps> = ({
    source='group_id',
    showCreateOption=true,
    ...props
}) => {
    const getResourceLabel = useGetResourceLabel();
    const translate = useTranslate();

    return (
        <ReferenceInput
            source={source}
            reference='prompt-groups'
        >
            <SelectInput
                variant='standard'
                validate={required()}
                optionText={(choice) => {
                    if ('color' in choice) {
                        return (
                            <RecordContextProvider value={choice}>
                                <PromptGroupField key={choice.id} />
                            </RecordContextProvider>
                        );
                    }
                    return choice.name;
                }}
                create={showCreateOption ? <PromptGroupCreateInSelect /> : undefined}
                margin='none'
                createLabel={translate('label.add_new', {name: getResourceLabel('prompt-groups', 1)})}
                {...props}
            />
        </ReferenceInput>
    );
};

export default PromptGroupInput;
