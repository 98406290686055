// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { DataProvider } from 'react-admin';

import { ResourceURL } from '@/utils';

export default <DataProvider> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getList: async (resource: string, params: any) => {
        const url = ResourceURL(process.env.REACT_APP_BACKEND_ASSISTANTS);
        const messages = await fetch(url);
        const data = await messages.json();
        return {
            data,
            total: data.length,
        };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getMany: (resource, params) => Promise.resolve({ data: [] }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOne: (resource, params) => Promise.resolve({ data: [] }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getManyReference: (resource, params) => Promise.resolve({ data: [] }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    create: (resource, params) => Promise.resolve({ data: [] }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    update: (resource, params) => Promise.resolve({ data: [] }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateMany: (resource, params) => Promise.resolve({ data: [] }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    delete: (resource, params) => Promise.resolve({ data: [] }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteMany: (resource, params) => Promise.resolve({ data: [] }),
};
