// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import { CreateBase, useCreateSuggestionContext } from 'react-admin';

import PromptGroupFormDialog from './PromptGroupFormDialog';

type PromptGroupCreateInSelectProps = object;

const PromptGroupCreateInSelect: FC<PromptGroupCreateInSelectProps> = () => {
    const { onCancel, onCreate } = useCreateSuggestionContext();

    return (
        <CreateBase
            resource='prompt-groups'
            redirect={false}
            mutationOptions={{
                onSuccess: (data) => onCreate(data),
            }}
        >
            <PromptGroupFormDialog
                open
                onClose={onCancel}
            />
        </CreateBase>
    )
};

export default PromptGroupCreateInSelect;
