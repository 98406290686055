// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';

type AssistantListProps = object

const AssistantList: FC<AssistantListProps> = () => (
    <div>
        <h1>AssistantList</h1>
    </div>
);

export default AssistantList;
