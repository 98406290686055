// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import MenuList from '@mui/material/MenuList';
import { styled } from '@mui/material/styles';
import ChatIcon from '@mui/icons-material/Chat';
import lodashGet from 'lodash/get';
import clsx from 'clsx';
import {
    MenuProps, useSidebarState, MenuClasses, ResourceMenuItems,
    DRAWER_WIDTH, CLOSED_DRAWER_WIDTH, useBasename, MenuItemLink
} from 'react-admin';

const PREFIX = 'RaMenu';

const Root = styled(MenuList, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.only('xs')]: {
        marginTop: 0,
    },
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),

    [`&.${MenuClasses.open}`]: {
        width: lodashGet(theme, 'sidebar.width', DRAWER_WIDTH),
    },

    [`&.${MenuClasses.closed}`]: {
        width: lodashGet(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
    },
}));

const Menu: FC<MenuProps> = () => {
    const [open] = useSidebarState();
    const basename = useBasename();

    return (
        <Root
            className={clsx(
                {
                    [MenuClasses.open]: open,
                    [MenuClasses.closed]: !open,
                }
            )}
        >
            <MenuItemLink
                leftIcon={<ChatIcon />}
                to={`${basename}/`}
                primaryText='label.chat.title'
            />
            <ResourceMenuItems />
        </Root>
    );
};

export default Menu;
