// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Chip from '@mui/material/Chip';
import { useLocale } from 'react-admin';

import { ModelProviderEntity } from '@/types';
import { MediaURL } from '@/utils';

type ProviderFieldProps = {
    providerEntity: ModelProviderEntity;
};

const ProviderField: FC<ProviderFieldProps> = ({
    providerEntity,
}) => {
    const locale = useLocale();

    const avatar = providerEntity.icon_small ? MediaURL(providerEntity.icon_small[locale]) : undefined;
    return (
        <Chip
            avatar={avatar ? (
                <img src={avatar} alt={providerEntity.label[locale]} style={{ height: '1rem' }} />
            ) : undefined}
            label={providerEntity.label[locale]}
        />
    );
}

export default ProviderField;
