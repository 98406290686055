// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { RaRecord, DataProvider } from 'react-admin';

import { I18nObject } from './system';
import { ModelProviderEntity } from './provider';
import { ParameterRule } from './common';

export enum ModelType {
    LLM = 'llm',
    TEXT_EMBEDDING = 'text-embedding',
    RERANK = 'rerank',
    SPEECH2TEXT = 'speech2text',
    MODERATION = 'moderation',
    TTS = 'tts',
    TEXT2IMG = 'text2img',
};

export enum ModelFeature {
    TOOL_CALL = 'tool-call',
    MULTI_TOOL_CALL = 'multi-tool-call',
    AGENT_THOUGHT = 'agent-thought',
    VISION = 'vision',
    STREAM_TOOL_CALL = 'stream-tool-call',
};

export enum ModelPropertyKey {
    MODE = 'mode',
    CONTEXT_SIZE = 'context_size',
    MAX_CHUNKS = 'max_chunks',
    FILE_UPLOAD_LIMIT = 'file_upload_limit',
    SUPPORTED_FILE_EXTENSIONS = 'supported_file_extensions',
    MAX_CHARACTERS_PER_CHUNK = 'max_characters_per_chunk',
    DEFAULT_VOICE = 'default_voice',
    VOICES = 'voices',
    WORD_LIMIT = 'word_limit',
    AUDIO_TYPE = 'audio_type',
    MAX_WORKERS = 'max_workers',
};

export type ModelProperty = {
    [key in ModelPropertyKey]: any;
};

export interface BaseModelEntity extends RaRecord {
    model: string;
    label: I18nObject;
    model_type: ModelType;
    features?: ModelFeature[];
    model_properties: ModelProperty;
    deprecated: boolean;
};

export interface ProviderModelWithStatusEntity extends BaseModelEntity {
    active: boolean;
};

export interface ModelWithProviderEntity extends ProviderModelWithStatusEntity {
    provider: ModelProviderEntity;
};

export interface ProviderModelEntity extends ModelWithProviderEntity {
    parameter_rules?: ParameterRule[];
}

export interface Parameters {
    [key: string]: number | string | boolean;
}

export interface ProviderModelConfigConfig {
    parameters: Parameters
}

export interface ProviderModelConfig {
    provider_name: string;
    model_name: string;
    config: ProviderModelConfigConfig;
}

export interface ProviderModelConfigWithEntity extends ProviderModelConfig {
    entity: ProviderModelEntity;
}

export interface ModelDataProvider extends DataProvider {
    getParameterRules: () => Promise<{ data: ParameterRule[], total: number }>;
}
