// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, {
    FC, useState, ReactNode, SyntheticEvent
} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ControlledAccordionProps {
    children: ReactNode | ReactNode[];
    panels: string[];
    titles: string[];
}

const ControlledAccordion: FC<ControlledAccordionProps> = ({ children, panels, titles}) => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return children && (
        <>
            {
                panels.map((panel, index) => (
                    <Accordion key={panel} expanded={expanded === panel} onChange={handleChange(panel)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${panel}-content`}
                            id={`${panel}-header`}
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                {titles[index]}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {Array.isArray(children) ? children[index] : children}
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </>
    );
};

export default ControlledAccordion;
