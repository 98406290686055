// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

export enum ParameterType {
    FLOAT = 'float',
    INT = 'int',
    BOOLEAN = 'boolean',
    STRING = 'string',
    SECRET = 'secret',
    TEXT = 'text',
    OBJECT = 'object',
};

export interface ParameterRule {
    name: string;
    label: {
        [key: string]: string;
    };
    placeholder: {
        [key: string]: string;
    };
    type: ParameterType;
    help: {
        [key: string]: string;
    };
    required: boolean;
    advanced: boolean;
    default: number | boolean | string;
};

export interface NumberParameterRule extends ParameterRule {
    min: number;
    max: number;
    precision?: number;
};

export type BooleanParameterRule = ParameterRule;

export interface TextParameterRule extends ParameterRule {
    options: ParameterRule[];
};

export type StringParameterRule = ParameterRule;

export interface ObjectParameterRule extends ParameterRule {
    parameters: ParameterRule[];
};

export interface MetaParams {
    queryParams?: {
        [key: string]: string;
    }
};
