// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps } from 'react-admin';
import TerminalIcon from '@mui/icons-material/Terminal';

import { PromptInterface } from './create';
import { PromptList } from './list';
import promptDataProvider from './promptDataProvider';

const PromptResourceProps: ResourceProps = {
    name: 'prompts',
    list: PromptList,
    create: PromptInterface,
    edit: PromptInterface,
    icon: TerminalIcon,
};

export {
    PromptResourceProps,
    promptDataProvider,
};
