// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    ListBase, Datagrid, TextField,
    ReferenceField, ExportButton, CreateButton,
    BulkDeleteWithConfirmButton, SearchInput,
    SelectInput,
} from 'react-admin';

import { PromptType, PromptStatus } from '@/types';
import { ListGridSwitcher } from '@/common';
import PromptInfoCard from './PromptInfoCard';
import { PromptGroupField, PromptGroupInput } from '@/prompt/promptGroup';
import { getChoicesFromEnum } from '@/utils';

const PromptListActions = [
    <CreateButton key='create' />,
    <ExportButton key='export' />,
];

const PromptFilters = [
    <SearchInput
        source='q'
        variant='standard'
    />,
    <PromptGroupInput
        source='group__id'
        showCreateOption={false}
        validate={undefined}
    />,
    <SelectInput
        source='type'
        choices={getChoicesFromEnum(PromptType)}
        variant='standard'
    />,
    <SelectInput
        source='status'
        choices={getChoicesFromEnum(PromptStatus)}
        variant='standard'
    />
];

const EventBulkActionButtons: FC = () => (
    <BulkDeleteWithConfirmButton mutationMode='pessimistic' />
);

type PromptListProps = object

const PromptList: FC<PromptListProps> = () => (
    <ListBase perPage={12}>
        <ListGridSwitcher
            actions={PromptListActions}
            filters={PromptFilters}
            ItemComponent={<PromptInfoCard />}
        >
            <Datagrid
                bulkActionButtons={<EventBulkActionButtons />}
            >
                <TextField source='name' />
                <ReferenceField source='group.id' reference='prompt-groups'>
                    <PromptGroupField />
                </ReferenceField>
                <TextField source='type' />
                <TextField source='status' />
            </Datagrid>
        </ListGridSwitcher>
    </ListBase>
);

export default PromptList;
