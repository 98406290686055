// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';

import { ParameterRule } from '@/types';
import { ConfigurationInput, ConfigurationInputProps, ControlledAccordion } from '@/common';

interface ModelParametersConfigurationProps {
    onChange: ConfigurationInputProps['onChange'];
    rules: ParameterRule[];
}

export const ModelParametersConfiguration: FC<ModelParametersConfigurationProps> = ({
    onChange,
    rules
}) => (
    <Stack spacing={1} sx={{ p: 1 }}>
        <Grid container spacing={1}>
            {
                rules.map((rule: ParameterRule, index: React.Key | null | undefined) => (
                    <Grid key={index} size={{ xs: 12, sm: rule.type === 'text' ? 12 : 6 }}>
                        <ConfigurationInput
                            key={index}
                            config={rule}
                            onChange={onChange}
                            defaultValue={rule.default}
                        />
                    </Grid>
                ))
            }
        </Grid>
    </Stack>
)

interface ModelParametersAdvancedConfigurationProps extends ModelParametersConfigurationProps {
    title: string;
};

export const ModelParametersAdvancedConfiguration: FC<ModelParametersAdvancedConfigurationProps> = ({
    onChange,
    rules,
    title,
}) => (
    <ControlledAccordion
        panels={['advanced']}
        titles={[title]}
    >
        <ModelParametersConfiguration
            onChange={onChange}
            rules={rules}
        />
    </ControlledAccordion>
);

interface ModelParametersInputProps {
    modelId?: string;
    rules?: ParameterRule[];
    onChange?: ConfigurationInputProps['onChange'];
    advancedSectionTitle: string
}

const ModelParametersInput: FC<ModelParametersInputProps> = ({
    rules,
    onChange,
    advancedSectionTitle
}) => {
    if (!rules) {
        return null;
    }

    const advancedRules = rules.filter((rule) => rule.advanced);
    const basicRules = rules.filter((rule) => !rule.advanced);

    return (
        <>
            <ModelParametersConfiguration
                onChange={onChange}
                rules={basicRules}
            />
            <ModelParametersAdvancedConfiguration
                onChange={onChange}
                rules={advancedRules}
                title={advancedSectionTitle}
            />
        </>
    );
};

export default ModelParametersInput;
