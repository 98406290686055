// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    ReferenceInput, AutocompleteInput, TextInput,
    AutocompleteInputProps, ReferenceInputProps,
    useLocale,
} from 'react-admin';
import { useFormContext } from 'react-hook-form'

import { ModelPropertyKey, ModelWithProviderEntity } from '@/types';
import ModelField from './ModelField';

interface ModelSelectInputProps extends Omit<AutocompleteInputProps, 'source' | 'filter' | 'disabled'> {
    filter: ReferenceInputProps['filter'];
    disabled?: boolean;
    onChange?: AutocompleteInputProps['onChange'];
};

const ModelSelectInput: FC<ModelSelectInputProps> = ({
    filter,
    disabled,
    onChange,
    ...rest
}) => {
    const { setValue } = useFormContext();
    const locale = useLocale();

    const handleModelChange: AutocompleteInputProps['onChange'] = (
        value,
        record
    ) => {
        if (!record) {
            return;
        }
        setValue('related_model.model_name', (record as ModelWithProviderEntity).model);
        setValue('related_model.provider_name', (record as ModelWithProviderEntity).provider.provider);
        if (onChange) {
            onChange(value, record);
        }
    };

    return (
        <>
            <ReferenceInput
                source='related_model.model_name'
                reference='models'
                filter={filter}
                perPage={-1}
                disabled={disabled}
            >
                <AutocompleteInput
                    debounce={500}
                    optionText={(choice) => (
                        <ModelField
                            key={choice.id}
                            model={choice}
                            properties={[
                                ModelPropertyKey.MODE,
                                ModelPropertyKey.CONTEXT_SIZE,
                            ]}
                        />
                    )}
                    inputText={(choice) => choice?.label[locale]}
                    margin='none'
                    groupBy={(choice) => choice?.provider.label[locale]}
                    onChange={handleModelChange}
                    {...rest}
                />
            </ReferenceInput>
            <TextInput
                type='hidden'
                source='related_model.provider_name'
                sx={{ display: 'none' }}
            />
        </>
    );
};

export default ModelSelectInput;
