// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, ReactNode } from 'react';
import { IconButton as MuiIconButton, IconButtonProps } from '@mui/material';
import { useTranslate } from 'react-admin';

interface BIconProps extends IconButtonProps {
    ariaLabel?: string;
    children: ReactNode;
};

const IconButton: FC<BIconProps> = ({ children, ariaLabel, ...rest }) => {
    const translate = useTranslate();

    return (
        <MuiIconButton
            aria-label={ariaLabel ? translate(ariaLabel) : ''}
            {...rest}
        >
            {children}
        </MuiIconButton>
    );
};

export default IconButton;
