// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import StopIcon from '@mui/icons-material/Stop';
import { useTranslate, useRecordContext } from 'react-admin';

import { SessionContext } from '@/context';
import { Session } from '@/types';
import { useChatHandler } from '@/hook';

import { WithTooltip, IconButton, TextareaAutosize } from '@/common';

type ChatInputProps = object

const ChatInput: FC<ChatInputProps> = () => {
    const session = useRecordContext<Session>();

    const translate = useTranslate();

    const [, setIsTyping] = useState<boolean>(false)

    const {
        userInput, setUserInput, isGenerating, sessionMessages,
    } = useContext(SessionContext);
    const { chatInputRef, handleSendMessage, handleStopMessage } = useChatHandler();

    const handleInputChange = (value: string): void => {
        setUserInput(value);
    }

    return (
        <TextareaAutosize
            formControlProps={{
                disabled: !session,
                sx: { width: '100%' },
            }}
            inputProps={{
                ref: chatInputRef,
                minRows: 1,
                maxRows: 18,
                placeholder: translate('label.chat.input.placeholder'),
                value: userInput,
                onCompositionStart: () => setIsTyping(true),
                onCompositionEnd: () => setIsTyping(false),
                onChange: (e) => handleInputChange(e.target.value),
                startAdornment: (
                    <InputAdornment position='start' sx={{marginTop: '0 !important'}}>
                        <WithTooltip
                            title='label.chat.input.attach'
                            trigger={(
                                <span>
                                    <IconButton
                                        edge='start'
                                        aria-label='label.chat.input.attach'
                                        disabled={!session}
                                    >
                                        <AttachFileIcon />
                                    </IconButton>
                                </span>
                            )}
                            arrow
                        />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position='end'>
                        <WithTooltip
                            title='label.chat.input.send'
                            trigger={(
                                <span>
                                    <IconButton
                                        edge='end'
                                        aria-label={isGenerating ? 'label.chat.input.stop' : 'label.chat.input.send'}
                                        disabled={!userInput}
                                        onClick={isGenerating ? (
                                            handleStopMessage
                                        ) : (
                                            () => {
                                                if (!userInput) return;
                                                handleSendMessage(userInput, sessionMessages)
                                            }
                                        )}
                                    >
                                        {
                                            isGenerating ? (
                                                <StopIcon />
                                            ) : (
                                                <SendIcon />
                                            )
                                        }
                                    </IconButton>
                                </span>
                            )}
                            arrow
                        />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default ChatInput;
