// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps, ListGuesser } from 'react-admin';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

// import CollectionList from './CollectionList';
import collectionDataProvider from './collectionDataProvider';

const CollectionResourceProps: ResourceProps = {
    name: 'collections',
    list: ListGuesser,
    icon: LocalLibraryIcon,
};

export {
    CollectionResourceProps,
    collectionDataProvider,
};
