// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ModelDataProvider } from '@/types';
import { ResourceURL } from '@/utils';
import { fetchJsonWithAuthToken } from '@/authProvider';

export default <ModelDataProvider> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getParameterRules: async (resource: string, params: any) => {
        const url = ResourceURL(`/${resource}/${params.id}/parameter_rules?per_page=all`);
        const { json } = await fetchJsonWithAuthToken(url);

        return {
            data: json.results,
            total: json.count,
        };
    }
};
