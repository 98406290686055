// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useEffect, useState } from 'react';
import {
    required, useTranslate,
    TextInput, useDataProvider,
    Labeled,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { ImageInput } from '../../common';

import { ParameterRule } from '@/types';
import { ModelSelectInput, ModelParametersInput } from '@/provider/model';
import { PromptGroupInput } from '@/prompt/promptGroup';

type ModelParametersSectionProps = object;

export const ModelParametersSection: FC<ModelParametersSectionProps> = () => {
    const modelName = useWatch({ name: 'related_model.model_name' });
    const [rules, setRules] = useState<ParameterRule[]>([]);
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchRules = async (): Promise<void> => {
            const convertedRules = await dataProvider.getParameterRules('models', {
                id: modelName,
            }).then((parametersData: any) => {
                const { data: parameterRules } = parametersData;
                return parameterRules.map((rule: ParameterRule) => {
                    const inputName = `related_model.config.parameters.${rule.name}`;
                    return {
                        ...rule,
                        name: inputName,
                    };
                });
            });
            setRules(convertedRules);
        }
        if (modelName) {
            fetchRules();
        }
    }, [modelName]);

    if (!modelName) {
        return null;
    }

    if (rules.length === 0) {
        return null;
    }

    return (
        <Labeled source='related_model.config.parameters'>
            <ModelParametersInput
                rules={rules}
                advancedSectionTitle={translate('label.advanced')}
            />
        </Labeled>
    );
};

type PromptCreateBasicInputsProps = object

export const PromptBasicInputs: FC<PromptCreateBasicInputsProps> = () => (
    <>
        <ImageInput
            source='avatar'
            sx={{
                flexDirection: 'row',
                '& .RaImageField-image': {
                    width: '100px !important',
                    height: '50px !important',
                },
            }}
        />
        <PromptGroupInput />
        <TextInput
            source='name'
            variant='outlined'
            validate={required()}
        />
        <TextInput
            source='description'
            variant='outlined'
            validate={required()}
            multiline
            rows={2}
        />
        <TextInput
            source='pre_prompt'
            variant='outlined'
            validate={required()}
            multiline
            rows={4}
        />
        <ModelSelectInput
            variant='outlined'
            validate={required()}
            filter={{ model_type: 'llm', valid_only: true }}
        />
    </>
);
