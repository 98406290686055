// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps } from 'react-admin';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import TeamList from './TeamList';
import TeamMenuItem from './TeamMenuItem';

const TeamResourceProps: ResourceProps = {
    name: 'teams',
    list: TeamList,
    icon: PeopleOutlineIcon,
};

export {
    TeamResourceProps,
    TeamMenuItem,
};
