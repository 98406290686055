// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    Form, useTranslate, SaveButton,
    useGetResourceLabel,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Dialog, CancelButton } from '@/common';

import PromptGroupInputs from './PromptGroupInputs';

interface PromptGroupFormDialogActionProps {
    onClose: () => void;
};

const PromptGroupFormDialogAction: FC<PromptGroupFormDialogActionProps> = ({
    onClose
}) => {
    const { reset } = useFormContext();

    return (
        <>
            <CancelButton
                onClick={() => {
                    reset();
                    onClose();
                }}
            />
            <SaveButton
                type='button'
            />
        </>
    );
}

interface PromptGroupFormDialogProps {
    open: boolean;
    onClose: () => void;
};

const PromptGroupFormDialog: FC<PromptGroupFormDialogProps> = ({
    open,
    onClose,
}) => {
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();

    return (
        // ToDo: <form> cannot appear as a descendant of <form>.
        <Form>
            <Dialog
                open={open}
                onClose={onClose}
                title={translate('label.add_new', {name: getResourceLabel('prompt-groups', 1)})}
                maxWidth='sm'
                dialogContent={(
                    <PromptGroupInputs />
                )}
                dialogAction={() => (
                    <PromptGroupFormDialogAction
                        onClose={onClose}
                    />
                )}
                fullWidth
            />
        </Form>
    );
};

export default PromptGroupFormDialog;
