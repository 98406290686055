// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    SelectInput, BooleanInput,
    required, useLocale,
    TextInput
} from 'react-admin';

import {
    ParameterType, ParameterRule, NumberParameterRule,
    BooleanParameterRule,
    TextParameterRule,
} from '@/types';
import { SliderInput, TextareaAutosize } from '@/common';

export interface ConfigurationInputProps {
    defaultValue?: any;
    config: ParameterRule;
    onChange?: (value: any, config: ParameterRule) => void;
}

const ConfigurationInput: FC<ConfigurationInputProps> = ({ defaultValue, config, onChange }) => {
    const locale = useLocale();

    const defaultParams = (): any => ({
        source: config.name,
        label: config.label[locale],
        variant: 'outlined',
        fullWidth: true,
        onChange: onChange ? (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value, config) : undefined,
    });

    switch(config.type) {
        case ParameterType.FLOAT:
        case ParameterType.INT: {
            // const numConfig = config as NumberInputConfiguration;
            // return (
            //     <NumberInput
            //         validate={
            //             numConfig.required ?
            //                 [
            //                     required(),
            //                     minValue(numConfig.min),
            //                     maxValue(numConfig.max),
            //                 ] : [
            //                     minValue(numConfig.min),
            //                     maxValue(numConfig.max),
            //                 ]
            //         }
            //         defaultValue={defaultValue}
            //         min={numConfig.min}
            //         max={numConfig.max}
            //         step={numConfig.precision ? 1 / 10**numConfig.precision : 1}
            //         {...defaultParams()}

            //     />
            // );
            const numberConfig = config as NumberParameterRule;

            return (
                <SliderInput
                    validate={numberConfig.required ? [required()] : []}
                    defaultValue={defaultValue}
                    min={numberConfig.min}
                    max={numberConfig.max}
                    step={numberConfig.precision ? 1 / 10**numberConfig.precision : 1}
                    margin='dense'
                    {...defaultParams()}
                />
            );
        }
        case ParameterType.SECRET:
        case ParameterType.STRING: {
            const texConfig = config as TextParameterRule;
            if (texConfig.options && texConfig.options.length > 0) {
                return (
                    <SelectInput
                        choices={
                            texConfig.options.map(
                                (item) => ({
                                    id: item.name,
                                    name: item.label[locale],
                                }),
                            )
                        }
                        defaultValue={defaultValue}
                        validate={texConfig.required ? [required()] : []}
                        {...defaultParams()}
                    />
                );
            }
            return (
                <TextInput
                    defaultValue={defaultValue}
                    validate={texConfig.required ? [required()] : []}
                    {...defaultParams()}
                    inputProps={{
                        type: texConfig.type === ParameterType.SECRET ? 'password' : 'text',
                    }}
                />
            );
        }
        case ParameterType.BOOLEAN: {
            const boolConfig = config as BooleanParameterRule;
            return (
                <BooleanInput
                    validate={boolConfig.required ? [required()] : []}
                    options={{
                        checkedIcon: <CheckCircleIcon />,
                    }}
                    defaultValue={defaultValue}
                    {...defaultParams()}
                />
            );
        }
        case ParameterType.TEXT: {
            // const textConfig = config as TextInputConfiguration;
            return (
                <TextareaAutosize />
            );
        }
        default:
            return null;
    }
};

export default ConfigurationInput;
