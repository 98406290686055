// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import { useLocale, useRecordContext } from 'react-admin';

import { ProviderConfiguration } from '@/types';
import { MediaURL } from '@/utils';

import ProviderSetup from './ProviderSetup';

type ProviderCardProps = object;

const ProviderCard: FC<ProviderCardProps> = () => {
    const locale = useLocale();
    const record = useRecordContext<ProviderConfiguration>();

    if (!record) {
        return null;
    }

    // sx={{ backgroundColor: record.background ? record.background : 'transparent' }}
    return (
        <Card>
            <CardHeader
                title={
                    record.icon_large ? (
                        <img
                            src={MediaURL(record.icon_large[locale])}
                            alt={record.label[locale]}
                            style={{ height: '1.5rem' }}
                        />
                    ) : (
                        record.label[locale]
                    )
                }
                subheader={record.description ? record.description[locale] : ''}
                action={(
                    <ProviderSetup />
                )}
            />
            <CardContent>
                {
                    record.supported_model_types.map((modelType: string) => (
                        <Chip key={modelType} label={modelType.toUpperCase()} />
                    ))
                }
            </CardContent>
        </Card>
    );
};

export default ProviderCard;
